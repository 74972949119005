import React, { useState, useEffect } from "react"
import { useTranslation } from 'react-i18next';
import { useQueryParam, StringParam } from "use-query-params";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import _i18n from './i18n';
import { EP, callApi } from '../api';
import { ORDER_STATUS } from '../constants';
import useFormInput from './use-form-input';
import Loading from './loading';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  message: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(12),
      marginBottom: theme.spacing(10),
    },
  },
  formControl: {
    textAlign: 'left',
  }
}));

const Message = ({ header, body }) => {
  const classes = useStyles();
  return (
    <div className={classes.message}>
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
        {header}
      </Typography>
      <Typography variant="h5" align="center" color="textSecondary" component="p" dangerouslySetInnerHTML={{__html: body }} />
    </div>
  )
};


const ConfirmOrderForm = ({ order }) => {
  const { t } = useTranslation('confirm');
  const classes = useStyles();
  const { locationFailed } = order;
  const [sid] = useQueryParam('n', StringParam);
  const methodType = useFormInput(locationFailed ? order.customerData.methodType : '');
  const isCar = methodType.value === 'car';
  const customerData = ((order.customerData || {}).method || {})[methodType.value] || {};
  const plateNumber = useFormInput((locationFailed && isCar) ? customerData.plateNumber : '');
  const carColour = useFormInput((locationFailed && isCar) ? customerData.carColour : '');
  const carMake = useFormInput((locationFailed && isCar) ? customerData.carMake : '');
  const carModel = useFormInput((locationFailed && isCar) ? customerData.carModel : '');
  const details = useFormInput(locationFailed ? customerData.details : '');
  const [confirm, setConfirm] = useState({ isCreating: false, isError: false, isSuccess: false, error: null }); 
  const isInvalidForm = 
    (isCar && (!plateNumber.isValid || !carColour.isValid)) 
    || (!isCar && !details.isValid)
    || (locationFailed && !details.isValid); 
  const handleOnConfirm = async e => {
    e.preventDefault();
    setConfirm({ ...confirm, isConfirming: true });
    try {
      const params = {
        tenantId: order.tenantId,
        storeId: order.storeId,
        orderId: order.id,
        body: {
          sid,
          methodType: methodType.value,
          method: isCar
            ? { 
                car: {
                plateNumber: plateNumber.value,
                carColour: carColour.value,
                carMake: carMake.value,
                carModel: carModel.value,
                details: details.value,
              }
            }
           : { 
              other: {
                details: details.value
              }
            },
        }
      };
      await callApi(EP.orders.anonPost, params);
      setConfirm({ ...confirm, isConfirming: false, isError: false, isSuccess: true, error: null });
    } catch(error) {
      console.log(error);
      const message = error.response && error.response.data && error.response.data.message; 
      setConfirm({ ...confirm, isConfirming: false, isError: true, isSuccess: false, error: message });
    }
  }
  if (confirm.isError && confirm.error) return <Message header={t('errorTitle')} body={confirm.error} />
  if (confirm.isError && !confirm.error) return <Message header={t('errorTitle')} body={t('errorBody')} />
  if (confirm.isSuccess || [ORDER_STATUS.customerArrived, ORDER_STATUS.carriedOut].includes(order.status)) return <Message header={t('thanks')} body={t('confirmSuccess', {interpolation: {escapeValue: false}})} />
  if (order.status === ORDER_STATUS.delivered) return <Message header={t('thanks')} body={t('confirmThanks')} />
  if (order.status === ORDER_STATUS.cancelled) return <Message header={t('sorry')} body={t('confirmCancelled')} />

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          { locationFailed ? t('titleLocationFailed') : t('title') }
        </Typography>
        <Typography variant="overline" color="textSecondary">
          {t('order')} #{order.id}
        </Typography>
        <Typography>
          { locationFailed ? t('subtitleLocationFailed') : t('subtitle') }
        </Typography>
        { locationFailed && (
          <Typography>
            <br/>
            { t('detailsYouProvided') }
          </Typography>
        )}
        <form className={classes.form} noValidate>
          <FormControl required fullWidth variant="outlined" margin="normal" className={classes.formControl}>
            <InputLabel id="method-type-label">{t('methodType')}</InputLabel>
            <Select
              labelId="method-type-label"
              id="methodType"
              fullWidth
              required
              value={methodType.value}
              onChange={methodType.onChange}
              label={t('methodType')}
            >
              <MenuItem value='car'>{t('methodCar')}</MenuItem>
              <MenuItem value='other'>{t('methodOther')}</MenuItem>
            </Select>
          </FormControl>
          { isCar && (
            <React.Fragment>
              <TextField
                inputProps={{
                  readOnly: Boolean(confirm.isConfirming),
                }}
                fullWidth
                id="plateNumber"
                label={t('plateNumber')}
                margin="normal"
                name="plateNumber"
                required
                type="text"
                variant="outlined"
                value={plateNumber.value}
                onChange={plateNumber.onChange}
              />
              <TextField
                inputProps={{
                  readOnly: Boolean(confirm.isConfirming),
                }}
                fullWidth
                id="carColour"
                label={t('carColour')}
                margin="normal"
                name="carColour"
                required
                type="text"
                variant="outlined"
                value={carColour.value}
                onChange={carColour.onChange}
              />
              <TextField
                inputProps={{
                  readOnly: Boolean(confirm.isConfirming),
                }}
                fullWidth
                id="carMake"
                label={t('carMake')}
                margin="normal"
                name="carMake"
                type="text"
                variant="outlined"
                value={carMake.value}
                onChange={carMake.onChange}
              />
              <TextField
                inputProps={{
                  readOnly: Boolean(confirm.isConfirming),
                }}
                fullWidth
                id="carModel"
                label={t('carModel')}
                margin="normal"
                name="carModel"
                type="text"
                variant="outlined"
                value={carModel.value}
                onChange={carModel.onChange}
              />
            </React.Fragment>
          )}

          { methodType.value && (
            <TextField
              inputProps={{
                readOnly: Boolean(confirm.isConfirming),
              }}
              fullWidth
              id="details"
              label={ isCar ? t('additionalDetails') : t('details') }
              margin="normal"
              name="details"
              type="text"
              variant="outlined"
              multiline
              margin="normal"
              rows={4}
              required={!isCar || locationFailed}
              value={details.value}
              onChange={details.onChange}
            />
          )}
          <Button
            disabled={confirm.isConfirming || isInvalidForm}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleOnConfirm}
          >
            { confirm.isConfirming ? t('submitting') : t(locationFailed ? 'ctaLocationFailed' : 'cta') }
          </Button>
          { locationFailed && order.storeDetails && order.storeDetails.phone && (
            <Typography>
              <br/>
              { t('callTheStore') }
              <br />
              { order.storeDetails.phone }
            </Typography>
          )}
        </form>
      </div>
    </Container>
  )
}

const ConfirmOrder = () => {
  const [order] = useOrder();
  return order ? <ConfirmOrderForm order={order} /> : <Loading />;
}

function useOrder() {
  const { i18n } = useTranslation('confirm');
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [sid] = useQueryParam('n', StringParam);
  useEffect(() => {
    if (i18n && i18n.changeLanguage && !order && !loading && !error) {
      async function getOrder() {
        try {
          setLoading(true);
          setError();
          const orderResult = await callApi(EP.orders.anonGet, { sid });
          orderResult && orderResult.lang && i18n.changeLanguage(orderResult.lang);
          setOrder(orderResult);
        } catch (error) {
          console.log(error);
          setError('Could not get order');
        } finally {
          setLoading(false);
        }
      }
      getOrder();
    }
  });

  return [order, loading, error];
}

export default ConfirmOrder;
