import React, { useState, useEffect } from "react"
import Amplify, { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import { ThemeProvider } from '@material-ui/core/styles';

import amplifyConfig from '../amplify';
import Copyright from "../components/copyright"
import Loading from "../components/loading"
import ConfirmOrder from '../components/confirm-order';
import theme from '../theme';
import useTenantData from '../components/use-tenant-data';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(2, 0, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 0, 4),
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(8),
    },
  },
  logoAvatar: {
    backgroundColor: '#FFFFFF',
    height: theme.spacing(8),
    width: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(12),
      width: theme.spacing(12),
      marginBottom: theme.spacing(1),
    },
  },
}));


const ConfirmOrderPage = () => {
  const classes = useStyles();
  const { tenantData, loading } = useTenantData();
  const [user] = useAuth();
  if (loading) return <Loading />;
  const logoUrl = tenantData && tenantData.details && tenantData.details.logoUrl;
  return (
    <ThemeProvider theme={theme(tenantData)}>
      <Container align="center"  maxWidth="sm" component="main" className={classes.heroContent}>
        <div className={classes.paper}>
          <Avatar align="center"  alt="Logo" variant="rounded" size="small" src={logoUrl} className={classes.logoAvatar} />
          { user ? <ConfirmOrder /> : <Loading/> }
        </div>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}


function useAuth() {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (!user && !loading && !error) {
      async function auth() {
        try {
          setLoading(true);
          setError();
          const config = amplifyConfig();
          Amplify.configure(config);
          const anonymousUser = await Auth.currentCredentials();
          setUser(anonymousUser);
        } catch (error) {
          console.log(error);
          setError(error.message || 'Could not login');
        } finally {
          setLoading(false);
        }
      }
      auth();
    }
  }, [user, loading, error]);

  return [user, loading, error];
}

export default ConfirmOrderPage
